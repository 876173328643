<template>
  <div>
    <b-modal
      id="modal-input"
      size="lg"
      centered
      title="Tambah Surat Perintah Karyawan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            No. Surat Perintah <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            v-model="$v.data.nomorSurat.$model"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Data Karyawan <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.data.dataKaryawanId.$model"
            :state="checkIfValid('dataKaryawanId')"
            :options="karyawan"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            @input="itikiwir(data.dataKaryawanId)"
            track-by="suratPerjanjianKerjaId"
            label="dataKaryawan"
            placeholder="-- Pilih Karyawan --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label> No. Surat Perjanjian </template>
          <b-form-input
            type="text"
            v-model="noSuratPerjanjian"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Mitra <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.data.mitraId.$model"
            :state="checkIfValid('mitraId')"
            :options="dataMitra"
            @input="getKoordinator(data.mitraId), setPenempatan()"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaMitra"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Koordinator <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.data.koordinatorId.$model"
            :state="checkIfValid('koordinatorId')"
            :options="koordinator"
            @input="getCabang(data.koordinatorId), setPenempatan()"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaKoordinator"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Nama Cabang" label-cols-md="3">
          <multiselect
            v-model="data.cabangId"
            :options="cabang"
            @input="getCapem(data.cabangId), setPenempatan()"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaCabang"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Nama Capem" label-cols-md="3">
          <multiselect
            v-model="data.capemId"
            :options="capem"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaCapem"
            placeholder="-- Pilih --"
            @input="setPenempatan()"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal Mulai Kontrak <span class="text-danger">*</span>
          </template>
          <date-picker
            v-model="$v.data.tanggalKontrak.$model"
            :state="checkIfValid('tanggalKontrak')"
            style="width: 100%"
            @input="setExpire()"
          ></date-picker>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Durasi Kontrak (bulan) <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="number"
            v-model="$v.data.masaBerlakuKontrak.$model"
            :state="checkIfValid('masaBerlakuKontrak')"
            style="width: 100%"
            @input="setExpire()"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Selesai Kontrak <span class="text-danger">*</span>
          </template>
          <date-picker
            v-model="$v.data.jatuhTempoKontrak.$model"
            :state="checkIfValid('jatuhTempoKontrak')"
            style="width: 100%"
            :placeholder="place"
          ></date-picker>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Jabatan Manajemen <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.data.posisiManajemenId.$model"
            :options="manajemen"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="id"
            @input="
              (data.namaPejabatManajemen =
                data.posisiManajemenId.namaManajemen),
                (data.posisiManajemen = data.posisiManajemenId.jabatanManajemen)
            "
            label="jabatanManajemen"
            placeholder="-- Pilih Posisi --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Manajemen <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            v-model="data.namaPejabatManajemen"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Penempatan <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            v-model="data.penempatan"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-input')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          @click="simpan()"
          :disabled="!isValid || busy"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
import 'moment/locale/id'
export default {
  components: { Multiselect, DatePicker },
  props: ["karyawan", "dataMitra",'manajemen'],
  name: "modalInput",
  data() {
    return {
      koordinator: [],
      cabang: [],
      capem: [],
      place:"",
      data: {
        nomorSurat: "",
        dataKaryawanId: "",
        tanggalKontrak: "",
        suratPerjanjianKerjaId: "",
        mitraId: "",
        cabangId: "",
        koordinatorId: "",
        capemId: "",
        masaBerlakuKontrak: "",
        jatuhTempoKontrak:"",
        penempatan: "",
        namaPejabatManajemen: "",
        posisiManajemenId: "",
        posisiManajemen: "",
      },
      noSuratPerjanjian: "",
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.data, null, 4);
    },
    isValid() {
      return !this.$v.data.$invalid;
    },
    isDirty() {
      return this.$v.data.$anyDirty;
    },
  },
  mounted() {
    this.getManajemen();
  },
  mixins: [validationMixin],
  validations: {
    data: {
      dataKaryawanId: {
        required,
      },
      tanggalKontrak: {
        required,
      },
      masaBerlakuKontrak: {
        required,
      },
      jatuhTempoKontrak:{
        required
      },
      mitraId: {
        required,
      },
      koordinatorId: {
        required,
      },
      nomorSurat: {
        required,
      },
      posisiManajemenId: {
        required,
      },
    },
  },
  mounted() {
    // this.getManajemen();
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.data[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    itikiwir(x) {
      console.log(x);
      let vm = this;
      vm.noSuratPerjanjian = x.nomorSurat;
      vm.data.suratPerjanjianKerjaId = x.suratPerjanjianKerjaId;
    },
    async getManajemen() {
      let vm = this;
      let manajemen = await axios.get(ipBackend + "masterManajemen/list");
      vm.manajemen = manajemen.data.data;
    },
    setPenempatan() {
      let vm = this;
      console.log(vm.data.mitraId);
      if (vm.data.capemId) {
        vm.data.penempatan =
          vm.data.capemId.namaCapem;
      } else {
        if (vm.data.cabangId) {
          vm.data.penempatan =
            vm.data.cabangId.namaCabang;
        } else {
          if (vm.data.koordinatorId) {
            vm.data.penempatan =
              vm.data.koordinatorId.namaKoordinator;
          } else {
            vm.data.penempatan = vm.data.mitraId.namaMitra;
          }
        }
      }
    },
    async getKoordinator(x) {
      let koordinator = await axios.get(
        ipBackend + "koordinator/listKoordinatorByMitraId/" + x.id
      );
      //   console.log(koordinator.data.data, "ini koordinator");
      this.koordinator = koordinator.data.data;
    },
    async getCabang(x) {
      let cabang = await axios.get(
        ipBackend + "cabang/listCabangByKoordinatorId/" + x.id
      );
      //   console.log(cabang.data.data, "ini cabang");
      this.cabang = cabang.data.data;
    },
    async getCapem(x) {
      let capem = await axios.get(
        ipBackend + "capem/listCapemByCabangId/" + x.id
      );
      //   console.log(cabang.data.data, "ini cabang");
      this.capem = capem.data.data;
    },
    setExpire() {
      let vm = this;
      vm.data.jatuhTempoKontrak = moment(vm.data.tanggalKontrak).add(vm.data.masaBerlakuKontrak, "month").subtract(1, "days");
      vm.place = moment(vm.data.tanggalKontrak).add(vm.data.masaBerlakuKontrak, "month").subtract(1, "days").format("YYYY-MM-DD")
      // console.log(vm.data.jatuhTempoKontrak);
    },
    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.data.dataKaryawanId = vm.data.dataKaryawanId.dataKaryawanId;
      vm.data.mitraId = vm.data.mitraId.id;
      vm.data.koordinatorId = vm.data.koordinatorId.id;
      vm.data.cabangId = vm.data.cabangId.id;
      vm.data.capemId = vm.data.capemId.id;
      vm.data.tanggalKontrak = moment(vm.data.tanggalKontrak);
      vm.data.jatuhTempoKontrak = moment(vm.data.jatuhTempoKontrak)
      axios
        .post(ipBackend + "poolKontrak/register", vm.data)
        .then((res) => {
          if (res.data.status == 200) {
            if (res.data.message == "sukses") {
              vm.button = "Simpan";
              vm.$v.$reset();
              vm.resetForm()
              vm.busy = false;
              vm.$emit("alert", {
                variant: "success",
                msg: "BERHASIL MENDAFTARKAN KONTRAK KARYAWAN",
                showing: true,
              });
              this.$bvModal.hide("modal-input");
            } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$v.$reset();
            vm.resetForm()
            vm.$emit("alert", {
              variant: "danger",
              msg: res.data.message.toUpperCase(),
              showing: true,
            });
            this.$bvModal.hide("modal-input");
          }
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
    resetForm(){
      this.data= {
        nomorSurat: "",
        dataKaryawanId: "",
        tanggalKontrak: "",
        suratPerjanjianKerjaId: "",
        mitraId: "",
        cabangId: "",
        koordinatorId: "",
        capemId: "",
        masaBerlakuKontrak: "",
        penempatan: "",
        namaPejabatManajemen: "",
        posisiManajemenId: "",
        posisiManajemen: "",
      }
    }
  },
};
</script>
