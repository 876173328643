<template>
  <div>
    <b-modal
      id="modal-edit"
      size="lg"
      centered
      title="Edit Surat Perintah Karyawan"
      header-bg-variant="primary"
      header-text-variant="light"
    >
      <b-form>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            No. Surat Perintah <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            v-model="$v.dataEdit.nomorSuratPerintah.$model"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Data Karyawan <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="text"
            v-model="data.namaKaryawan"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label> No. Surat Perjanjian </template>
          <b-form-input
            type="text"
            v-model="noSuratPerjanjian"
            disabled
          ></b-form-input>
        </b-form-group>

        <b-form-group label="Nama Mitra" label-cols-md="3">
          <multiselect
            v-model="$v.dataEdit.mitraIdEdit.$model"
            :state="checkIfValid('mitraIdEdit')"
            :options="dataMitra"
            @input="getKoordinators(dataEdit.mitraIdEdit), setPenempatan()"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaMitra"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Nama Koordinator" label-cols-md="3">
          <multiselect
            v-model="dataEdit.koordinatorIdEdit"
            :options="koordinator"
            @input="getCabangs(dataEdit.koordinatorIdEdit), setPenempatan()"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaKoordinator"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Nama Cabang" label-cols-md="3">
          <multiselect
            v-model="dataEdit.cabangIdEdit"
            :options="cabang"
            @input="getCapems(dataEdit.cabangIdEdit), setPenempatan()"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaCabang"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>
        <b-form-group label="Nama Capem" label-cols-md="3">
          <multiselect
            v-model="dataEdit.capemIdEdit"
            :options="capem"
            @input="setPenempatan()"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            label="namaCapem"
            placeholder="-- Pilih --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Tanggal Mulai Kontrak <span class="text-danger">*</span>
          </template>
          <date-picker
            v-model="dataEdit.tanggalKontrak"
            style="width: 100%"
            :placeholder="data.tanggalKontrakEdit"
            @input="setExpire()"
          ></date-picker>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Durasi Kontrak (bulan) <span class="text-danger">*</span>
          </template>
          <b-form-input
            type="number"
            v-model="$v.dataEdit.masaBerlakuKontrak.$model"
            :state="checkIfValid('masaBerlakuKontrak')"
            style="width: 100%"
            @input="setExpire()"
          ></b-form-input>
        </b-form-group>
        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Selesai Kontrak <span class="text-danger">*</span>
          </template>
          <date-picker
            v-model="dataEdit.jatuhTempoKontrak"
            style="width: 100%"
            :placeholder="
              moment(dataEdit.jatuhTempoKontrak).format('DD-MM-YYYY')
            "
          ></date-picker>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Jabatan Manajemen <span class="text-danger">*</span>
          </template>
          <multiselect
            v-model="$v.dataEdit.posisiManajemenId.$model"
            :options="manajemen"
            :multiple="false"
            :searchable="true"
            :close-on-select="true"
            :show-labels="false"
            track-by="id"
            @input="
              (dataEdit.namaPejabatManajemen =
                dataEdit.posisiManajemenId.namaManajemen),
                (dataEdit.posisiManajemen =
                  dataEdit.posisiManajemenId.jabatanManajemen)
            "
            label="jabatanManajemen"
            placeholder="-- Pilih Posisi --"
          ></multiselect>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Nama Manajemen <span class="text-danger">*</span>
          </template>
          <b-form-input
            size="lg"
            type="text"
            v-model="dataEdit.namaPejabatManajemen"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>

        <b-form-group label-cols-md="3">
          <template v-slot:label>
            Penempatan <span class="text-danger">*</span>
          </template>
          <b-form-input
            size="lg"
            type="text"
            v-model="dataEdit.penempatan"
            style="width: 100%"
          ></b-form-input>
        </b-form-group>
      </b-form>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-edit')">
          Batal
        </b-button>
        <b-button
          variant="primary"
          @click="simpan()"
          :disabled="!isValid || busy"
        >
          Simpan
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import { validationMixin } from "vuelidate";
import { required } from "vuelidate/lib/validators";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import moment from "moment";
export default {
  components: { Multiselect, DatePicker },
  props: ["karyawan", "data", "dataMitra", "edit"],
  name: "modalEdit",
  data() {
    return {
      moment,
      dataEdit: {
        tanggalKontrak: "",
        masaBerlakuKontrak: "",
        jatuhTempoKontrak: "",
        dataKaryawanId: "",
        mitraIdEdit: "",
        koordinatorIdEdit: "",
        cabangIdEdit: "",
        capemIdEdit: "",
        nomorSuratPerintah: "",
        suratPerjanjianKerjaId: "",
        posisiManajemenId: "",
        namaPejabatManajemen: "",
        posisiManajemen: "",
      },
      noSuratPerjanjian: "",
      cabang: [],
      koordinator: [],
      capem: [],
      manajemen: [],
      busy: false,
      button: "Simpan",
    };
  },
  computed: {
    formString() {
      return JSON.stringify(this.dataEdit, null, 4);
    },
    isValid() {
      return !this.$v.dataEdit.$invalid;
    },
    isDirty() {
      return this.$v.dataEdit.$anyDirty;
    },
  },
  mixins: [validationMixin],
  validations: {
    dataEdit: {
      masaBerlakuKontrak: {
        required,
      },
      mitraIdEdit: {
        required,
      },
      // koordinatorIdEdit: {
      //   required,
      // },
      nomorSuratPerintah: {
        required,
      },
      posisiManajemenId: {
        required,
      },
    },
  },
  watch: {
    edit: function (newVal, oldVal) {
      if (oldVal != newVal) {
        this.dataEdit = {
          tanggalKontrak: "",
          masaBerlakuKontrak: "",
          jatuhTempoKontrak:"",
          dataKaryawanId: "",
          mitraIdEdit: "",
          koordinatorIdEdit: "",
          cabangIdEdit: "",
          capemIdEdit: "",
          nomorSuratPerintah: "",
          suratPerjanjianKerjaId: "",
          posisiManajemenId: "",
          namaPejabatManajemen: "",
          posisiManajemen: "",
          penempatan:""
        };
        // console.log(this.data.mitraIdEdit, "ini mitra");
        for (let i = 0; i < this.dataMitra.length; i++) {
          if (this.dataMitra[i].id == this.data.mitraId) {
            this.dataEdit.mitraIdEdit = this.dataMitra[i];
          }
        }
        console.log(this.data);
        this.dataEdit.koordinatorIdEdit = "";
        this.dataEdit.cabangIdEdit = "";
        this.dataEdit.capemIdEdit = "";
        this.dataEdit.nomorSuratPerintah = this.data.nomorSuratPerintah;
        this.dataEdit.tanggalKontrak = this.data.tanggalKontrak;
        this.dataEdit.masaBerlakuKontrak = this.data.masaBerlakuKontrak;
        this.dataEdit.jatuhTempoKontrak = this.data.jatuhTempoKontrak;
        this.dataEdit.suratPerjanjianKerjaId = this.data.suratPerjanjianKerjaId;
        this.dataEdit.dataKaryawanId = this.data.dataKaryawanId;
        this.dataEdit.namaPejabatManajemen = this.data.namaPejabatManajemen;
        this.noSuratPerjanjian = this.data.nomorSuratPerjanjian;
        this.dataEdit.penempatan = this.data.penempatan;
        this.getKoordinator(this.dataEdit.mitraIdEdit);
        this.getManajemens();
      }
    },
  },
  methods: {
    checkIfValid(fieldName) {
      const field = this.$v.dataEdit[fieldName];
      if (!field.$dirty) {
        return null;
      }
      return !(field.$invalid || field.$model === "");
    },
    async getKoordinator(x) {
      let koordinator = await axios.get(
        ipBackend + "koordinator/listKoordinatorByMitraId/" + x.id
      );
      this.koordinator = koordinator.data.data;
      for (let i = 0; i < this.koordinator.length; i++) {
        if (this.koordinator[i].koordinatorId == this.data.koordinatorId) {
          this.dataEdit.koordinatorIdEdit = this.koordinator[i];
        }
      }
      this.getCabang(this.dataEdit.koordinatorIdEdit);
    },
    async getKoordinators(x) {
      let koordinator = await axios.get(
        ipBackend + "koordinator/listKoordinatorByMitraId/" + x.id
      );
      this.koordinator = koordinator.data.data;
      this.dataEdit.koordinatorIdEdit = "";
      this.dataEdit.cabangIdEdit = "";
      this.dataEdit.capemIdEdit = "";
    },
    async getCabang(x) {
      let cabang = await axios.get(
        ipBackend + "cabang/listCabangByKoordinatorId/" + x.id
      );
      console.log(cabang, "ini cabang");
      this.cabang = cabang.data.data;
      for (let i = 0; i < this.cabang.length; i++) {
        if (this.cabang[i].cabangId == this.data.cabangId) {
          this.dataEdit.cabangIdEdit = this.cabang[i];
        }
      }
      this.getCapem(this.dataEdit.cabangIdEdit);
    },
    async getCabangs(x) {
      let cabang = await axios.get(
        ipBackend + "cabang/listCabangByKoordinatorId/" + x.id
      );
      this.cabang = cabang.data.data;
      this.dataEdit.cabangIdEdit = "";
      this.dataEdit.capemIdEdit = "";
    },
    async getCapem(x) {
      let capem = await axios.get(
        ipBackend + "capem/listCapemByCabangId/" + x.id
      );
      this.capem = capem.data.data;
      for (let i = 0; i < this.capem.length; i++) {
        if (this.capem[i].capemId == this.data.capemId) {
          this.dataEdit.capemIdEdit = this.capem[i];
        }
      }
    },
    async getCapems(x) {
      let capem = await axios.get(
        ipBackend + "capem/listCapemByCabangId/" + x.id
      );
      this.capem = capem.data.data;
      this.dataEdit.capemIdEdit = "";
    },
    async getManajemens() {
      let manajemen = await axios.get(ipBackend + "masterManajemen/list");
      this.manajemen = manajemen.data.data;
      console.log(this.manajemen, this.data, "jemen");

      for (let i = 0; i < this.manajemen.length; i++) {
        if (this.manajemen[i].jabatanManajemen == this.data.posisiManajemen) {
          this.dataEdit.posisiManajemenId = this.manajemen[i];
        }
      }
    },
    setExpire() {
      let vm = this;
      vm.dataEdit.jatuhTempoKontrak = moment(vm.dataEdit.tanggalKontrak)
        .add(vm.dataEdit.masaBerlakuKontrak, "month")
        .subtract(1, "days");
      vm.place = moment(vm.dataEdit.tanggalKontrak)
        .add(vm.dataEdit.masaBerlakuKontrak, "month")
        .subtract(1, "days")
        .format("YYYY-MM-DD");
    },
    setPenempatan() {
      let vm = this;
      console.log(vm.dataEdit.mitraIdEdit,'mitra');
      if (vm.dataEdit.capemIdEdit) {
        vm.dataEdit.penempatan = vm.dataEdit.capemIdEdit.namaCapem;
      } else {
        if (vm.dataEdit.cabangIdEdit) {
          vm.dataEdit.penempatan = vm.dataEdit.cabangIdEdit.namaCabang;
        } else {
          if (vm.dataEdit.koordinatorIdEdit) {
            vm.dataEdit.penempatan = vm.dataEdit.koordinatorIdEdit.namaKoordinator;
          } else {
            vm.dataEdit.penempatan = vm.dataEdit.mitraIdEdit.namaMitra;
          }
        }
      }
    },
    simpan() {
      let vm = this;
      vm.button = "Mohon Tunggu";
      vm.busy = true;
      vm.dataEdit.id = vm.data.poolKontrakId;
      vm.dataEdit.mitraId = vm.dataEdit.mitraIdEdit.id;
      vm.dataEdit.koordinatorId = vm.dataEdit.koordinatorIdEdit.koordinatorId;
      vm.dataEdit.capemId = vm.dataEdit.capemIdEdit.capemId;
      vm.dataEdit.cabangId = vm.dataEdit.cabangIdEdit.cabangId;
      vm.dataEdit.tanggalKontrak = moment(vm.dataEdit.tanggalKontrak);
      vm.dataEdit.jatuhTempoKontrak = moment(vm.dataEdit.jatuhTempoKontrak);
      console.log(vm.dataEdit, "ini data edit");
      axios
        .post(ipBackend + "poolKontrak/update", vm.dataEdit)
        .then((res) => {
          if (res.data.message == "sukses") {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "success",
              msg: "BERHASIL MENGUBAH KONTRAK KARYAWAN",
              showing: true,
            });
            this.$bvModal.hide("modal-edit");
          } else {
            vm.button = "Simpan";
            vm.busy = false;
            vm.$emit("alert", {
              variant: "danger",
              msg: _.toUpper(res.data.message),
              showing: true,
            });
          }
        })
        .catch((err) => {
          vm.button = "Simpan";
          vm.busy = false;
          vm.$emit("alert", {
            variant: "danger",
            msg: "TERJADI KESALAHAN PADA SERVER",
            showing: true,
          });
        });
    },
  },
};
</script>
