<template>
  <div>
    <b-container fluid>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-card header-tag="header" header-bg-variant="dark">
            <template #header>
              <h5 class="mb-0" style="color: #fff">
                <strong>{{ $store.state.table.perintah }}</strong>
              </h5>
            </template>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <!-- <b-button variant="primary" v-b-modal.modal-input
                  ><CIcon name="cil-plus" />
                  {{ $store.state.table.tambahModal }}</b-button
                > -->
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <hr />
              </b-col>
            </b-row>
            <b-alert dismissible fade :show="showing" :variant="variant">{{
              msg
            }}</b-alert>

            <b-row>
              <b-col md="5">
                <b-form-group
                  label="Pilih Mitra"
                  label-cols-md="5"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <multiselect
                    v-model="mitraId"
                    :options="dataMitra"
                    @input="getKoordinator(mitraId)"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="namaMitra"
                    placeholder="-- Pilih --"
                  ></multiselect>
                </b-form-group>
                <b-form-group
                  label="Pilih Koordinator"
                  label-cols-md="5"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <multiselect
                    v-model="koordinatorId"
                    :options="koordinator"
                    @input="getCabang(koordinatorId)"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="namaKoordinator"
                    placeholder="-- Pilih --"
                  ></multiselect>
                </b-form-group>
              </b-col>
              <b-col md="5">
                <b-form-group
                  label="Pilih Cabang"
                  label-cols-md="5"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <multiselect
                    v-model="cabangId"
                    :options="cabang"
                    @input="getCapem(cabangId)"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="namaCabang"
                    placeholder="-- Pilih --"
                  ></multiselect>
                </b-form-group>
                <b-form-group
                  label="Pilih Capem"
                  label-cols-md="5"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <multiselect
                    v-model="capemId"
                    :options="capem"
                    :multiple="false"
                    :searchable="true"
                    :close-on-select="true"
                    :show-labels="false"
                    label="namaCapem"
                    placeholder="-- Pilih --"
                  ></multiselect>
                </b-form-group>
              </b-col>
              <b-col md="2">
                <b-col md="1">
                  <b-button
                    @click="filterring()"
                    style="margin-top: 10px"
                    variant="primary"
                    >Simpan</b-button
                  >
                </b-col>
                <b-col md="1">
                  <b-button
                    @click="reset()"
                    style="margin-top: 10px"
                    variant="light"
                    >Reset</b-button
                  >
                </b-col>
              </b-col>

              <b-col md="6" offset-md="6">
                <b-form-group
                  :label="$store.state.table.cari"
                  label-for="filter-input"
                  label-cols-md="3"
                  label-align-md="right"
                  label-size="md"
                >
                  <b-input-group size="md">
                    <b-form-input
                      id="filter-input"
                      v-model.lazy="filter"
                      type="search"
                      :placeholder="$store.state.table.cariHolder"
                    ></b-form-input>

                    <b-input-group-append>
                      <b-button
                        :disabled="!filter"
                        @click="filter = ''"
                        variant="danger"
                        >{{ $store.state.table.hapusCari }}</b-button
                      >
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col cols="12" md="12" lg="12">
                <b-table
                  :items="items"
                  :fields="fields"
                  :current-page="currentPage"
                  :per-page="perPage"
                  :filter="filter"
                  :filter-included-fields="filterOn"
                  stacked="md"
                  responsive
                  show-empty
                  small
                  @filtered="onFiltered"
                  bordered
                  striped
                  hover
                  :busy="tableBusy"
                >
                  <!-- <template #cell(no)="item">
                    {{ item.index + 1 }}
                  </template> -->
                  <template #cell(tanggalKontrak)="item">
                    {{ moment(item.item.tanggalKontrak).format("LL") }}
                  </template>
                  <template #cell(jatuhTempoKontrak)="item">
                    {{ moment(item.item.jatuhTempoKontrak).format("LL") }}
                  </template>
                  <template #cell(statusPoolKontrakAktif)="item">
                    <p v-if="item.item.statusPoolKontrakAktif == 0">Aktif</p>
                    <p v-if="item.item.statusPoolKontrakAktif == 1">
                      Habis Kontrak
                    </p>
                    <p v-if="item.item.statusPoolKontrakAktif == 2">
                      Diberhentikan
                    </p>
                    <p v-if="item.item.statusPoolKontrakAktif == 3">Resign</p>
                    <p v-if="item.item.statusPoolKontrakAktif == 4">
                      Diperpanjang
                    </p>
                    <p v-if="item.item.statusPoolKontrakAktif == 5">
                      Mutasi Penempatan
                    </p>
                    <p v-if="item.item.statusPoolKontrakAktif == 6">
                      Meninggal
                    </p>
                    <p v-if="item.item.statusPoolKontrakAktif == 7">
                      Tanpa Keterangan
                    </p>
                    <p v-if="item.item.statusPoolKontrakAktif == 8">
                      Belum Aktif
                    </p>
                    <p v-if="item.item.statusPoolKontrakAktif == 9">Pensiun</p>
                  </template>
                  <template #cell(actions)="item">
                    <b-button
                      v-if="item.item.statusPoolKontrakAktif == 8"
                      variant="warning"
                      class="mr-1 mb-1"
                      v-c-tooltip.hover.click="'Edit Data'"
                      v-b-modal.modal-edit
                      @click="(data = item.item), setEdit(), (edit = !edit)"
                      ><CIcon name="cil-pencil" />{{ item.actions }}</b-button
                    >

                    <b-button
                      variant="info"
                      class="mr-1 mb-1"
                      v-c-tooltip.hover.click="'Detail'"
                      v-b-modal.modal-detail
                      @click="(data = item.item), setEdit(), (detail = !detail)"
                      ><CIcon name="cil-info" />{{ item.actions }}</b-button
                    >
                    <!-- <b-button
                      v-if="
                        (item.item.statusPoolKontrakAktif == 0 ||
                          item.item.statusPoolKontrakAktif == 8) &&
                        !item.item.isMutasi
                      "
                      variant="outline-danger"
                      class="mr-1 mb-1"
                      v-c-tooltip.hover.click="'Status'"
                      v-b-modal.modal-status-perintah-kerja
                      @click="(data = item.item), setEdit(), (detail = !detail)"
                      ><CIcon name="cil-check" />{{ item.actions }}</b-button
                    > -->
                    <b-button
                      variant="success"
                      class="mr-1 mb-1"
                      v-c-tooltip.hover.click="'Upload'"
                      v-b-modal.modal-dokumen-perintah-kerja
                      @click="(data = item.item), setEdit(), (detail = !detail)"
                      ><CIcon name="cil-paperclip" />{{
                        item.actions
                      }}</b-button
                    >

                    <b-button
                      variant="secondary"
                      class="mr-1 mb-1"
                      v-c-tooltip.hover.click="'Print Data'"
                      v-b-modal.modal-print
                      @click="setPrint(item.item)"
                      ><CIcon name="cil-print" />{{ item.actions }}</b-button
                    >

                    <b-button
                      v-if="
                        item.item.statusPoolKontrakAktif == 8 &&
                        !item.item.isMutasi
                      "
                      variant="danger"
                      class="mr-1 mb-1"
                      v-c-tooltip.hover.click="'Hapus Data'"
                      v-b-modal.modal-delete
                      @click="data = item.item"
                      ><CIcon name="cil-trash" />{{ item.actions }}</b-button
                    >
                  </template>
                </b-table>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <b-form-group
                  :label="$store.state.table.perHalaman"
                  label-for="per-page-select"
                  label-cols-md="6"
                  label-align-md="left"
                  label-size="md"
                  style="background-color: "
                >
                  <b-form-select
                    id="per-page-select"
                    v-model="perPage"
                    :options="pageOptions"
                    size="sm"
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col md="3">
                <b-row>
                  <b-col> Total Data {{ totalRows }}</b-col></b-row>
              </b-col>
              <b-col md="5" offset-md="4">
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  align="fill"
                  size="sm"
                ></b-pagination>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </b-container>

    <ModalInput
      :dataMitra="dataMitra"
      :karyawan="karyawan"
      :manajemen="manajemen"
      @alert="triggerAlert($event)"
    />

    <ModalEdit
      :data="data"
      :edit="edit"
      :karyawan="karyawan"
      :dataMitra="dataMitra"
      @alert="triggerAlert($event)"
    />
    <ModalDetail :data="data" :detail="detail" />
    <ModalDokumen :data="data" :detail="detail" @alert="triggerAlert($event)" />
    <ModalDelete :data="data" :detail="detail" @alert="triggerAlert($event)" />
    <ModalStatus :data="data" :detail="detail" @alert="triggerAlert($event)" />
    <ModalPrint :printData="printData" :manajemen="manajemen" />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend.js";
import ModalInput from "@/component/hcms/karyawan/kontrak/modalInput.vue";
import ModalEdit from "@/component/hcms/karyawan/kontrak/modalEdit.vue";
import ModalDokumen from "@/component/hcms/karyawan/kontrak/modalDokumen.vue";
import moment from "moment";
moment.locale("id");
import ModalDetail from "@/component/hcms/karyawan/kontrak/modalDetail.vue";
import ModalStatus from "@/component/hcms/karyawan/kontrak/modalStatus.vue";
import ModalDelete from "@/component/hcms/karyawan/kontrak/modalDelete.vue";
import ModalPrint from "@/component/hcms/karyawan/kontrak/modalPrint.vue";
import Multiselect from "vue-multiselect";
export default {
  components: {
    ModalEdit,
    ModalDetail,
    ModalInput,
    Multiselect,
    ModalDokumen,
    ModalStatus,
    ModalDelete,
    ModalPrint,
  },
  data() {
    return {
      moment,
      showing: false,
      variant: "success",
      asuransi: [],
      dataAsuransi: "",
      msg: "",
      data: "",
      printData: null,
      edit: false,
      detail: false,
      mitraId: "",
      cabangId: "",
      koordinatorId: "",
      capemId: "",
      karyawan: [],
      manajemen:[],
      dataMitra: [],
      koordinator: [],
      cabang: [],
      capem: [],
      items: [
        {
          no: "-",
          nama: "-",
          alamat: "-",
          email: "-",
          divisi: "-",
          posisi: "-",
        },
      ],
      fields: [
        {
          key: "no",
          label: "No",
          sortDirection: "desc",
          sortable: true,
          class: "table-number text-center",
        },
        {
          key: "nomorSuratPerintah",
          label: "No. Surat",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "nomorSuratPerjanjian",
          label: "No Perjanjian",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaKaryawan",
          label: "Nama",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "tanggalKontrak",
          label: "Tanggal Mulai Kontrak",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "masaBerlakuKontrak",
          label: "Durasi (bulan)",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "namaMitra",
          label: "Mitra",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        // {
        //   key: "namaKoordinator",
        //   label: "Koordinator",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        // {
        //   key: "namaCabang",
        //   label: "Cabang",
        //   sortable: true,
        //   sortDirection: "desc",
        //   class: "text-left",
        // },
        {
          key: "penempatan",
          label: "Penempatan",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "statusPoolKontrakAktif",
          label: "Status",
          sortable: true,
          sortDirection: "desc",
          class: "text-left",
        },
        {
          key: "actions",
          label: "Actions",
          class: "table-option-5 text-center",
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 25,
      pageOptions: [10, 25, 50, 100],
      filter: null,
      filterOn: ["namaKaryawan", "nomorSuratPerintah"],
      tableBusy: false,
    };
  },
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  mounted() {
    // Set the initial number of items
    this.getDatas();
    this.getMitra();
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    async getMitra() {
      let listMitra = await axios.get(ipBackend + "mitra/list");
      this.dataMitra = listMitra.data.data;
    },
    async getKoordinator(x) {
      let koordinator = await axios.get(
        ipBackend + "koordinator/listKoordinatorByMitraId/" + x.id
      );
      //   console.log(koordinator.data.data, "ini koordinator");
      this.koordinator = koordinator.data.data;
      this.koordinatorId = "";
      this.cabangId = "";
      this.capemId = "";
    },
    async getCabang(x) {
      //   console.log(x, "ini korid");
      let cabang = await axios.get(
        ipBackend + "cabang/listCabangByKoordinatorId/" + x.id
      );
      //   console.log(cabang.data.data, "ini cabang");
      this.cabang = cabang.data.data;
      this.cabangId = "";
      this.capemId = "";
    },
    async getCapem(x) {
      let capem = await axios.get(
        ipBackend + "capem/listCapemByCabangId/" + x.id
      );
      //   console.log(cabang.data.data, "ini cabang");
      this.capem = capem.data.data;
      this.capemId = "";
    },
    setEdit() {
      let vm = this;
      for (let i = 0; i < vm.karyawan.length; i++) {
        if (vm.data.dataKaryawanId == vm.karyawan[i].dataKaryawanId) {
          vm.data.dataKaryawanIdEdit = vm.karyawan[i];
        }
        vm.data.tanggalKontrakEdit = moment(vm.data.tanggalKontrak).format(
          "DD/MM/YYYY"
        );
      }
    },
    async setPrint(x) {
      let vm = this;
      let print = await axios.get(
        ipBackend + "poolKontrak/cetakSuratPerintah/" + x.poolKontrakId
      );
      // console.log(print.data.data[0],'super');
      vm.printData = print.data.data[0];
      vm.printData.alamatKaryawanPrint = vm.printData.alamatKaryawan;
      vm.printData.tanggalKontrakPrint = moment(
        vm.printData.tanggalKontrak
      ).format("LL");
      vm.printData.jatuhTempoKontrakPrint = moment(
        vm.printData.jatuhTempoKontrak
      ).format("LL");
      vm.printData.tanggalDibuatSuratPrint = moment(
        vm.printData.tanggalDibuatSurat
      ).format("LL");
      vm.printData.namaPejabatManajemenModal = "";
      vm.printData.posisiManajemenModal = "";
    },
    async getDatas() {
      let vm = this;
      vm.tableBusy = true;
      let listKontrak = await axios.get(ipBackend + "poolKontrak/list");
      vm.items = listKontrak.data.data.map((item, index) => {
        item.no = index + 1;
        return item;
      });
      // console.log(listKontrak, "ini kontrak");
      vm.totalRows = vm.items.length;
      let listKaryawan = await axios.get(
        ipBackend + "suratPerjanjianKerja/listPerjanjianKerjaAktif"
      );

      // console.log(listKaryawan.data.data, "ini list karyawan");
      vm.karyawan = listKaryawan.data.data.map((item) => {
        item.dataKaryawan =
          item.namaKaryawan + " - " + item.namaDivisi + " - " + item.namaPosisi;
        return item;
      });
      let manajemen = await axios.get(ipBackend + "masterManajemen/list");
      vm.manajemen = manajemen.data.data;
      vm.tableBusy = false;
    },
    reset() {
      this.mitraId = "";
      this.koordinatorId = "";
      this.cabangId = "";
      this.capemId = "";
      this.getDatas();
    },
    filterring() {
      let vm = this;
      let dataFilter = {};
      if (vm.mitraId) {
        dataFilter.namaMitra = vm.mitraId.namaMitra;
      } else {
        dataFilter.namaMitra = "";
      }
      if (vm.koordinatorId) {
        dataFilter.namaKoordinator = vm.koordinatorId.namaKoordinator;
      } else {
        dataFilter.namaKoordinator = "";
      }
      if (vm.cabangId) {
        dataFilter.namaCabang = vm.cabangId.namaCabang;
      } else {
        dataFilter.namaCabang = "";
      }
      if (vm.capemId) {
        dataFilter.namaCapem = vm.capemId.namaCapem;
      } else {
        dataFilter.namaCapem = "";
      }
      //   console.log(dataFilter, "ini datanya");
      axios
        .post(ipBackend + "poolKontrak/filterKontrak", dataFilter)
        .then((res) => {
          // console.log(res, "ini res");
          this.items = res.data.data;
          // for (let i = 0; i < this.items.length; i++) {
          //   let x = this.items[i];
          //   x.no = i + 1;
          // }
          this.totalRows = this.items.length;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    triggerAlert(event) {
      let vm = this;
      vm.showing = event.showing;
      vm.variant = event.variant;
      vm.msg = event.msg;
      vm.getDatas();
      setTimeout(() => {
        vm.showing = false;
      }, 4000);
    },
    print(data) {
      window.open(
        ipBackend + "poolKontrak/cetakSuratPerintah/" + data.poolKontrakId,
        "_blank"
      );
    },
  },
};
</script>
