var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-modal',{attrs:{"id":"modal-print","centered":"","scrollable":"","title":"Cetak Surat Surat","header-bg-variant":"info","header-text-variant":"light"},scopedSlots:_vm._u([{key:"modal-footer",fn:function(){return [_c('b-button',{staticClass:"mr-1",attrs:{"variant":"secondary"},on:{"click":function($event){return _vm.$bvModal.hide('modal-print')}}},[_vm._v(" Batal ")])]},proxy:true}])},[_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Jabatan Manajemen ")]},proxy:true}])},[_c('multiselect',{attrs:{"options":_vm.manajemen,"multiple":false,"searchable":true,"close-on-select":true,"show-labels":false,"track-by":"id","label":"jabatanManajemen","placeholder":"-- Pilih Manajemen --"},on:{"input":function($event){(_vm.setData.namaPejabatManajemenModal = _vm.pic.namaManajemen),
                (_vm.setData.posisiManajemenModal = _vm.pic.jabatanManajemen)}},model:{value:(_vm.pic),callback:function ($$v) {_vm.pic=$$v},expression:"pic"}})],1)],1)],1),_c('b-row',[_c('b-col',[_c('b-form-group',{attrs:{"label-cols-md":"3"},scopedSlots:_vm._u([{key:"label",fn:function(){return [_vm._v(" Nama Manajemen ")]},proxy:true}])},[_c('b-form-input',{staticStyle:{"width":"100%"},attrs:{"type":"text"},model:{value:(_vm.setData.namaPejabatManajemenModal),callback:function ($$v) {_vm.$set(_vm.setData, "namaPejabatManajemenModal", $$v)},expression:"setData.namaPejabatManajemenModal"}})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12","lg":"12"}},[_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('b-button',{staticClass:"mr-1",attrs:{"block":"","variant":"secondary"},on:{"click":function($event){_vm.seturl('PetugasKebersihan.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = "Surat Penempatan " + (_vm.printData.namaKaryawan) + ".docx")}}},[_vm._v(" Print Surat Penempatan Petugas Kebersihan ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('b-button',{staticClass:"mr-1",attrs:{"block":"","variant":"secondary"},on:{"click":function($event){_vm.seturl('Pesuruh.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = "Surat Penempatan " + (_vm.printData.namaKaryawan) + ".docx")}}},[_vm._v(" Print Surat Penempatan Pesuruh ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('b-button',{staticClass:"mr-1",attrs:{"block":"","variant":"secondary"},on:{"click":function($event){_vm.seturl('Satpam.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = "Surat Penempatan " + (_vm.printData.namaKaryawan) + ".docx")}}},[_vm._v(" Print Surat Penempatan Satpam ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('b-button',{staticClass:"mr-1",attrs:{"block":"","variant":"secondary"},on:{"click":function($event){_vm.seturl('SatpamEclat.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = "Surat Penempatan " + (_vm.printData.namaKaryawan) + ".docx")}}},[_vm._v(" Print Surat Penempatan Satpam Mitra ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('b-button',{staticClass:"mr-1",attrs:{"block":"","variant":"secondary"},on:{"click":function($event){_vm.seturl('Pengemudi.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = "Surat Penempatan " + (_vm.printData.namaKaryawan) + ".docx")}}},[_vm._v(" Print Surat Penempatan Pengemudi ")])],1)],1),_c('b-row',{staticClass:"mt-2 mb-2"},[_c('b-col',[_c('b-button',{staticClass:"mr-1",attrs:{"block":"","variant":"secondary"},on:{"click":function($event){_vm.seturl('SuratPerintah.docx'),
                  (_vm.gen = !_vm.gen),
                  (_vm.name = "Surat Perintah " + (_vm.printData.namaKaryawan) + ".docx")}}},[_vm._v(" Print Surat Perintah ")])],1)],1)],1)],1)],1),_c('generator',{attrs:{"data":_vm.printData,"gen":_vm.gen,"url":_vm.url,"name":_vm.name}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }