<template>
  <div>
    <b-modal
      id="modal-print"
      centered
      scrollable
      title="Cetak Surat Surat"
      header-bg-variant="info"
      header-text-variant="light"
    >
      <b-row>
        <b-col>
          <b-form-group label-cols-md="3">
            <template v-slot:label> Jabatan Manajemen </template>
            <multiselect
              v-model="pic"
              :options="manajemen"
              :multiple="false"
              :searchable="true"
              :close-on-select="true"
              :show-labels="false"
              track-by="id"
              @input="
                (setData.namaPejabatManajemenModal = pic.namaManajemen),
                  (setData.posisiManajemenModal = pic.jabatanManajemen)
              "
              label="jabatanManajemen"
              placeholder="-- Pilih Manajemen --"
            ></multiselect>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col>
          <b-form-group label-cols-md="3">
            <template v-slot:label> Nama Manajemen </template>
            <b-form-input
              type="text"
              v-model="setData.namaPejabatManajemenModal"
              style="width: 100%"
            ></b-form-input>
          </b-form-group>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-row class="mt-2 mb-2">
            <b-col>
              <b-button
                class="mr-1"
                block
                variant="secondary"
                @click="
                  seturl('PetugasKebersihan.docx'),
                    (gen = !gen),
                    (name = `Surat Penempatan ${printData.namaKaryawan}.docx`)
                "
              >
                Print Surat Penempatan Petugas Kebersihan
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col>
              <b-button
                class="mr-1"
                block
                variant="secondary"
                @click="
                  seturl('Pesuruh.docx'),
                    (gen = !gen),
                    (name = `Surat Penempatan ${printData.namaKaryawan}.docx`)
                "
              >
                Print Surat Penempatan Pesuruh
              </b-button>
            </b-col> </b-row
          ><b-row class="mt-2 mb-2">
            <b-col>
              <b-button
                class="mr-1"
                block
                variant="secondary"
                @click="
                  seturl('Satpam.docx'),
                    (gen = !gen),
                    (name = `Surat Penempatan ${printData.namaKaryawan}.docx`)
                "
              >
                Print Surat Penempatan Satpam
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col>
              <b-button
                class="mr-1"
                block
                variant="secondary"
                @click="
                  seturl('SatpamEclat.docx'),
                    (gen = !gen),
                    (name = `Surat Penempatan ${printData.namaKaryawan}.docx`)
                "
              >
                Print Surat Penempatan Satpam Mitra
              </b-button>
            </b-col> </b-row
          ><b-row class="mt-2 mb-2">
            <b-col>
              <b-button
                class="mr-1"
                block
                variant="secondary"
                @click="
                  seturl('Pengemudi.docx'),
                    (gen = !gen),
                    (name = `Surat Penempatan ${printData.namaKaryawan}.docx`)
                "
              >
                Print Surat Penempatan Pengemudi
              </b-button>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col>
              <b-button
                class="mr-1"
                block
                variant="secondary"
                @click="
                  seturl('SuratPerintah.docx'),
                    (gen = !gen),
                    (name = `Surat Perintah ${printData.namaKaryawan}.docx`)
                "
              >
                Print Surat Perintah
              </b-button>
            </b-col>
          </b-row>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button
          class="mr-1"
          variant="secondary"
          @click="$bvModal.hide('modal-print')"
        >
          Batal
        </b-button>
      </template>
    </b-modal>
    <generator :data="printData" :gen="gen" :url="url" :name="name" />
  </div>
</template>

<script>
import axios from "axios";
import { ipBackend } from "@/ipBackend";
import generator from "@/component/DOCX/docx_generator.vue";
import moment from "moment";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import "moment/locale/id";
export default {
  components: {
    generator,
    Multiselect,
  },
  name: "modalDetail",
  props: ["printData", "manajemen"],
  data() {
    return {
      ipBackend,
      gen: false,
      name: "",
      url: "",
      pic: null,
      setData: {
        namaPejabatManajemenModal: null,
        posisiManajemenModal: null,
      },
    };
  },
  methods: {
    async seturl(x) {
      let vm = this;
      vm.printData.namaPejabatManajemenModal =
        vm.setData.namaPejabatManajemenModal;
      vm.posisiManajemenModal = vm.setData.posisiManajemenModal;
      vm.url = vm.ipBackend + "asset/docx/" + x;
      //   console.log(this.url);
    },
  },
};
</script>
