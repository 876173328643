<template>
  <div>
    <b-modal
      id="modal-detail"
      size="lg"
      centered
      scrollable
      title="Detail Perintah Karyawan"
      header-bg-variant="info"
      header-text-variant="light"
    >
    <div
        style="
          text-align: center;
          padding: 2%;
          font-weight: 600;
          font-size: 21px;
        "
      >
        {{ data.nomorSuratPerintah }}
      </div>
      <b-row>
        <b-col cols="12" md="12" lg="12">
          <b-table-simple borderless small>
            <b-tbody>
              <b-tr>
                <b-td style="width: 35%">Nama</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.namaKaryawan }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Mitra</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.namaMitra }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Koordinator</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.namaKoordinator }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Kantor Cabang</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.namaCabang }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Kantor Capem</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.namaCapem }}</b-td>
              </b-tr>

              <!-- <b-tr>
                <b-td>Status Karyawan</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.statusKerja }}</b-td>
              </b-tr> -->

              <b-tr>
                <b-td>Status Perintah Kerja</b-td>
                <b-td>:</b-td>
                <b-td
                  ><span v-if="data.statusPoolKontrakAktif == 0">Aktif</span>
                  <span v-if="data.statusPoolKontrakAktif == 1">Habis Kontrak</span>
                  <span v-if="data.statusPoolKontrakAktif == 2">Diberhentikan</span>
                  <span v-if="data.statusPoolKontrakAktif == 3">Resign</span>
                  <span v-if="data.statusPoolKontrakAktif == 4">Diperpanjang</span>
                  <span v-if="data.statusPoolKontrakAktif == 5">Mutasi Penempatan</span>
                  <span v-if="data.statusPoolKontrakAktif == 6">Meninggal</span>
                  <span v-if="data.statusPoolKontrakAktif == 7">
                    Tanpa Keterangan
                  </span>
                  <span v-if="data.statusPoolKontrakAktif == 8">
                    Belum Aktif
                  </span></b-td
                >
              </b-tr>

              <b-tr>
                <b-td>Tanggal Mulai Kontrak</b-td>
                <b-td>:</b-td>
                <b-td>{{ moment(data.tanggalKontrak).format("LL") }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Lama Kontrak</b-td>
                <b-td>:</b-td>
                <b-td>{{ data.masaBerlakuKontrak }} Bulan</b-td>
              </b-tr>

              <b-tr>
                <b-td>Tanggal Berakhir Kontrak</b-td>
                <b-td>:</b-td>
                <b-td>{{ moment(data.jatuhTempoKontrak).format("LL") }}</b-td>
              </b-tr>

              <b-tr>
                <b-td>Penempatan</b-td>
                <b-td>:</b-td>
                <b-td>{{data.penempatan}}</b-td>
              </b-tr>
            </b-tbody>
          </b-table-simple>
        </b-col>
      </b-row>
      <template #modal-footer>
        <b-button variant="secondary" @click="$bvModal.hide('modal-detail')">
          Batal
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { ipBackend } from "@/ipBackend";
import moment from "moment";
import "moment/locale/id";
export default {
  name: "modalDetail",
  props: ["data", "detail"],
  data() {
    return {
      moment,
      src1: "",
      src2: "",
    };
  },
  watch: {
    detail: function (newVal, oldVal) {
      if (oldVal != newVal) {
        if (this.data.statusKerjaKaryawan == 0) {
          this.data.statusKerja = "Kontrak";
        } else if (this.data.statusKerjaKaryawan == 1) {
          this.data.statusKerja = "Permanen";
        } else if (this.data.statusKerjaKaryawan == 2) {
          this.data.statusKerja = "Resign / Diberhentikan";
        }
        this.src1 = "";
        this.src2 = "";
        if (this.data.fotoProfilKaryawan != null) {
          this.src1 = `${ipBackend}asset/file/${this.data.fotoProfilKaryawan}`;
        }
        if (this.data.fotoKKKaryawan != null) {
          this.src2 = `${ipBackend}asset/file/${this.data.fotoKKKaryawan}`;
        }
      }
      // console.log(this.data);
    },
  },
};
</script>
